<template>
  <FormWrapper>
    <PageTitle
      title="New Joinee Information"
      slot="title"
      link="/helpContent/newJoinee"
    />
    <ValidationObserver ref="validator">
      <FormRow>
        <div class="col-sm-1">
          <FormSelect
            label="Salutation"
            :items="nameList"
            item-name="name"
            item-value="name"
            v-model="form.salutation"
            rules="required"
          />
        </div>
        <div class="col-sm-2">
          <InputText
            label="First Name"
            v-model="form.firstName"
            rules="required"
          />
        </div>
        <div class="col-sm-2">
          <InputText label="Middle Name" v-model="form.middleName" />
        </div>
        <div class="col-sm-2">
          <InputText
            label="Last Name"
            v-model="form.lastName"
            rules="required"
          />
        </div>
        <div class="col-sm-3">
          <InputText
            label="User Name"
            v-model="form.userName"
            rules="required"
          />
        </div>
        <div class="col-sm-1">
          <InputIntegerNumber
            label="Emp Code"
            v-model="form.empCode"
            :maxlength="5"
            rules="required"
          />
        </div>
      </FormRow>
      <FormRow>
        <div class="col-sm-3">
          <InputTel
            label="Mobile No"
            v-model="form.mobileNo"
            rules="required"
          />
        </div>
        <div class="col-sm-3">
          <InputTel
            label="Emergency Contact No"
            v-model="form.emergancyContactNo"
            rules="required"
          />
        </div>
        <div class="col-sm-3">
          <InputText
            label="Office E-mail"
            rules="required"
            v-model="form.emailId"
          />
        </div>
        <div class="col-sm-3">
          <InputText
            label="Personal E-mail"
            v-model="form.alternateEmail"
            rules="required"
          />
        </div>
      </FormRow>
      <FormRow>
        <div class="col-sm-1">
          <FormSelect
            :items="genderList"
            item-name="Name"
            item-value="id"
            label="Gender"
            v-model="form.gender"
            rules="required"
          />
        </div>
        <div class="col-sm-2">
          <FormSelect
            :items="bloodGroupList"
            item-name="BloodGroup"
            item-value="BloodGroup"
            label="Blood Group"
            v-model="form.bloodGroup"
            rules="required"
          />
        </div>
        <div class="col-sm-3">
          <InputIntegerNumber
            label="Aadhaar Number"
            :maxlength="12"
            rules="required"
            v-model="form.aadhaarNo"
          />
        </div>
        <div class="col-sm-2">
          <inputDate
            label="Date of Birth"
            v-model="form.DOB"
            rules="required"
          />
        </div>
      </FormRow>
      <br />
      <FormRow>
        <div class="col-sm-2">
          <FormSelect
            :items="managerList"
            item-name="Name"
            item-value="id"
            label="Manager"
            v-model="form.managerId"
            rules="required"
          />
        </div>
        <div class="col-sm-2">
          <FormSelect
            :items="divisionList"
            item-name="NAME"
            item-value="ID"
            label="Division"
            v-model="form.devisionId"
            rules="required"
          />
        </div>
        <div class="col-sm-2">
          <FormSelect
            :items="departmentList"
            item-name="Name"
            item-value="ID"
            label="Department"
            v-model="form.departmentId"
            rules="required"
          />
        </div>
        <div class="col-sm-2">
          <FormSelect
            :items="designationList"
            item-name="NAME"
            item-value="ID"
            label="Designation"
            v-model="form.designationId"
            rules="required"
          />
        </div>
        <div class="col-sm-1">
          <FormSelect
            label="Grade"
            :items="gradeList"
            item-name="Grade"
            item-value="Grade"
            v-model="form.gradeId"
            rules="required"
          />
        </div>
      </FormRow>
      <FormRow>
        <div class="col-sm-3">
          <FormSelect
            :items="shiftList"
            item-name="Name"
            item-value="id"
            label="Shift"
            v-model="form.shiftId"
            rules="required"
          />
        </div>
        <div class="col-sm-2">
          <inputDate
            rules="required"
            label="Date of Joining "
            v-model="form.dateOfJoining"
          />
        </div>
        <div class="col-sm-2">
          <inputDate
            rules="required"
            label="Date of Confirmation"
            v-model="form.dateOfConfirmation"
          />
        </div>
        <div class="col-sm-2">
          <FormCheckbox v-model="form.isManager">Is Manager</FormCheckbox>
        </div>
      </FormRow>
      <FormRow> </FormRow>

      <FormRow>
        <div class="col-sm-3">
          <h3>Permanent Address</h3>
        </div>
      </FormRow>
      <FormRow>
        <div class="col-sm-4">
          <InputText
            label="Permanent Address 1"
            v-model="form.permanentAddress1"
            rules="required"
          />
        </div>
        <div class="col-sm-4">
          <InputText
            label="Permanent Address 2"
            v-model="form.permanentAddress2"
          />
        </div>
        <div class="col-sm-4">
          <InputText
            label="Permanent Address 3"
            v-model="form.permanentAddress3"
          />
        </div>
      </FormRow>
      <FormRow>
        <div class="col-sm-3">
          <FormSelect
            label="Permanent Country"
            :items="countryList"
            item-name="Name"
            item-value="id"
            v-model="form.permanentCountryId"
            :onChange="getPermanentStateList"
            rules="required"
          />
        </div>
        <div class="col-sm-3">
          <FormSelect
            label="Permanent State"
            :items="permanentStateList"
            item-name="Name"
            item-value="id"
            v-model="form.permanentStateId"
            :onChange="getPermanentCityList"
            rules="required"
          />
        </div>
        <div class="col-sm-3">
          <FormSelect
            label="Permanent City"
            :items="permanentCityList"
            item-name="Name"
            item-value="id"
            v-model="form.permanentCityId"
            rules="required"
          />
        </div>
        <div class="col-sm-3">
          <InputText
            label="Permanent Zip Code"
            v-model="form.permanentZipCode"
            rules="required"
          />
        </div>
      </FormRow>
      <FormRow>
        <div class="col-sm-6">
          <FormCheckbox
            :onChange="setCommunicationAdddetails"
            v-model="form.isSameAsPermanentAdd"
            >Same as Permanent Address</FormCheckbox
          >
        </div>
      </FormRow>
      <FormRow>
        <div class="col-sm-3">
          <h3>Communication Address</h3>
        </div>
      </FormRow>
      <FormRow>
        <div class="col-sm-4">
          <InputText
            label="Communication Address 1"
            v-model="form.communicationAddress1"
            rules="required"
          />
        </div>
        <div class="col-sm-4">
          <InputText
            label="Communication Address 2"
            v-model="form.communicationAddress2"
          />
        </div>
        <div class="col-sm-4">
          <InputText
            label="Communication Address 3"
            v-model="form.communicationAddress3"
          />
        </div>
      </FormRow>
      <FormRow>
        <div class="col-sm-3">
          <FormSelect
            label="Communication Country"
            :items="countryList"
            item-name="Name"
            item-value="id"
            v-model="form.communicationCountryId"
            :onChange="getCommunicationStateList"
            rules="required"
          />
        </div>
        <div class="col-sm-3">
          <FormSelect
            label="Communication State"
            :items="communicationStateList"
            item-name="Name"
            item-value="id"
            v-model="form.communicationStateId"
            :onChange="getCommunicationCityList"
            rules="required"
          />
        </div>
        <div class="col-sm-3">
          <FormSelect
            label="Communication City"
            :items="communicationCityList"
            item-name="Name"
            item-value="id"
            v-model="form.communicationCityId"
            rules="required"
          />
        </div>
        <div class="col-sm-3">
          <InputText
            label="Communication Zip Code"
            v-model="form.communicationZipCode"
            rules="required"
          />
        </div>
      </FormRow>
    </ValidationObserver>
    <Popup
      title="Success"
      type="success"
      :value="showMessage.isVisible"
      @close="() => (showMessage.isVisible = false)"
      :closable="true"
    >
      {{ showMessage.message }}
      <div slot="footer">
        <FormButton
          @click="
            () => {
              $router.go(-1);
            }
          "
          >Ok</FormButton
        >
      </div>
    </Popup>
    <Popup
      title="Information"
      type="info"
      :value="infoMessage.isVisible"
      @close="() => (infoMessage.isVisible = false)"
      :closable="true"
    >
      {{ infoMessage.message }}
      <div slot="footer">
        <FormButton
          @click="
            () => {
              infoMessage.isVisible = false;
            }
          "
          >Ok</FormButton
        >
      </div>
    </Popup>
    <ButtonGroup>
      <FormButton
        type="info"
        @click="
          () => {
            $router.go(-1);
          }
        "
        >Back</FormButton
      >
      <FormButton type="success" @click="sendData()">Save</FormButton>
    </ButtonGroup>
  </FormWrapper>
</template>

<script>
import FormWrapper from "Components/form/FormWrapper";
import formStepsMixin from "Mixins/formStepsMixin";
import {
  getAllGrade,
  getBloodGroup,
  getCountry,
  getState,
  getCity,
  getDepartmentByCompanyId,
  getAllCompanyList,
  getAllDivision,
  getAllDesignation,
  getAllHRGroup,
  getAllShift,
  getReportingLocation,
  getManagerList,
  joineeInfo,
} from "../api";
import { ValidationObserver } from "vee-validate";
import PageTitle from "Components/layout/PageTitle";
import InputDate from "Components/form/InputDate";
import InputTel from "Components/form/InputTel";
import moment from "moment";
export default {
  name: "joinee",
  mixins: [formStepsMixin],
  components: {
    FormWrapper,
    PageTitle,
    ValidationObserver,
    InputDate,
    InputTel,
  },
  data() {
    return {
      showMessage: {
        isVisible: false,
      },
      infoMessage: {
        isVisible: false,
        message: "",
      },
      form: {
        salutation: null,
        firstName: "",
        middleName: "",
        lastName: "",
        empCode: "",
        gradeId: null,
        userName: "",
        mobileNo: "",
        emergancyContactNo: "",
        emailId: "",
        alternateEmail: "",
        DOB: "",
        bloodGroup: null,
        aadhaarNo: "",
        photoName: null,
        permanentAddress1: "",
        permanentAddress2: "",
        permanentAddress3: "",
        permanentCountryId: 1, //id=1 is for India
        permanentStateId: null,
        permanentCityId: null,
        permanentZipCode: "",
        isSameAsPermanentAdd: false,
        communicationAddress1: "",
        communicationAddress2: "",
        communicationAddress3: "",
        communicationCountryId: 1, //id=1 is for India
        communicationStateId: null,
        communicationCityId: null,
        communicationZipCode: "",
        //userDetails Page fields
        gender: null,
        nationality: "India",
        companyId: 21, //id=21 for NetEdge Computing Solutions Pvt Ltd
        devisionId: null,
        departmentId: null,
        designationId: null,
        managerId: null,
        shiftId: null,
        dateOfJoining: moment().format("YYYY-MM-DD"), //current day
        dateOfConfirmation: moment()
          .add(6, "M")
          .format("YYYY-MM-DD"),
        isManager: false,
      },
      nameList: [
        {
          name: "Mr.",
        },
        {
          name: "Mrs.",
        },
        {
          name: "Ms.",
        },
        {
          name: "Dr.",
        },
      ],
      genderList: [
        {
          id: "M",
          Name: "Male",
        },
        {
          id: "F",
          Name: "Female",
        },
      ],

      gradeList: [],
      bloodGroupList: [],
      countryList: [],
      permanentStateList: [],
      permanentCityList: [],
      communicationStateList: [],
      communicationCityList: [],
      companyList: [],
      divisionList: [],
      designationList: [],
      hrgroupList: [],
      shiftList: [],
      reportinglocationList: [],
      managerList: [],
      departmentList: [],
    };
  },
  computed: {
    userData() {
      return this.$store.state.user.user;
    },
  },
  watch: {},
  created() {
    this.getAllList();
  },
  methods: {
    async getList(ele) {
      if (this.form.companyId == null) {
        this.form.companyId = ele;
      }
      if (this.form.companyId) {
        const data = {
          companyId: this.form.companyId,
        };
        await getDepartmentByCompanyId(data).then((res) => {
          this.departmentList = res.data;
        });
        await getReportingLocation(data).then((res) => {
          this.reportinglocationList = res.data;
        });
      }
    },
    setCommunicationAdddetails() {
      setTimeout(() => {
        if (this.form.isSameAsPermanentAdd) {
          this.form.communicationAddress1 = this.form.permanentAddress1;
          this.form.communicationAddress2 = this.form.permanentAddress2;
          this.form.communicationAddress3 = this.form.permanentAddress3;
          this.form.communicationCountryId = this.form.permanentCountryId;
          this.form.communicationStateId = this.form.permanentStateId;
          this.form.communicationCityId = this.form.permanentCityId;
          this.form.communicationZipCode = this.form.permanentZipCode;
          this.preFillAddressDropdowns();
        } else {
          this.form.communicationAddress1 = "";
          this.form.communicationAddress2 = "";
          this.form.communicationAddress3 = "";
          this.form.communicationCountryId = null;
          this.form.communicationStateId = null;
          this.form.communicationCityId = null;
          this.form.communicationZipCode = "";
        }
      }, 50);
    },
    preFillAddressDropdowns() {
      setTimeout(() => {
        this.getPermanentStateList(this.form.permanentCountryId);
      }, 50);
      setTimeout(() => {
        this.getPermanentCityList(this.form.permanentStateId);
      }, 50);
      setTimeout(() => {
        this.getCommunicationStateList(this.form.communicationCountryId);
      }, 50);
      setTimeout(() => {
        this.getCommunicationCityList(this.form.communicationStateId);
      }, 50);
    },
    async getAllList() {
      await getAllGrade().then((res) => {
        this.gradeList = res.data;
      });
      await getBloodGroup().then((res) => {
        this.bloodGroupList = res.data;
      });
      await getCountry().then((res) => {
        this.countryList = res.data;
      });
      await getAllCompanyList().then((res) => {
        this.companyList = res.data;
      });
      await getAllDivision().then((res) => {
        this.divisionList = res.data;
      });
      await getAllDesignation().then((res) => {
        this.designationList = res.data;
      });
      await getAllHRGroup().then((res) => {
        this.hrgroupList = res.data;
      });
      await getAllShift().then((res) => {
        this.shiftList = res.data;
      });
      await getManagerList().then((res) => {
        this.managerList = res.data;
      });
      this.preFillAddressDropdowns();
      this.getList(this.form.companyId);
    },
    getPermanentStateList(ele) {
      this.form.permanentCountryId = ele;
      if (this.form.permanentCountryId) {
        const data = {
          countryId: this.form.permanentCountryId,
        };
        getState(data).then((res) => {
          this.permanentStateList = res.data;
        });
      }
    },
    getPermanentCityList(ele) {
      this.form.permanentStateId = ele;
      if (this.form.permanentStateId) {
        const data = {
          stateId: this.form.permanentStateId,
        };
        getCity(data).then((res) => {
          this.permanentCityList = res.data;
        });
      }
    },
    getCommunicationStateList(ele) {
      this.form.communicationCountryId = ele;
      if (this.form.communicationCountryId) {
        const data = {
          countryId: this.form.communicationCountryId,
        };
        getState(data).then((res) => {
          this.communicationStateList = res.data;
        });
      }
    },
    getCommunicationCityList(ele) {
      this.form.communicationStateId = ele;
      if (this.form.communicationStateId) {
        const data = {
          stateId: this.form.communicationStateId,
        };
        getCity(data).then((res) => {
          this.communicationCityList = res.data;
        });
      }
    },
    sendData() {
      this.$refs.validator.validate().then((res) => {
        if (res) {
          const data = {
            salutation: this.form.salutation,
            firstName: this.form.firstName,
            middleName: this.form.middleName,
            lastName: this.form.lastName,
            userName: this.form.userName,
            empCode: this.form.empCode,
            mobileNo: this.form.mobileNo,
            emergancyContactNo: this.form.emergancyContactNo,
            emailId: this.form.emailId,
            alternateEmail: this.form.alternateEmail,
            gender: this.form.gender,
            bloodGroup: this.form.bloodGroup,
            aadhaarNo: this.form.aadhaarNo,
            DOB: this.form.DOB,
            managerId: this.form.managerId,
            devisionId: this.form.devisionId,
            departmentId: this.form.departmentId,
            designationId: this.form.designationId,
            gradeId: this.form.gradeId,
            shiftId: this.form.shiftId,
            dateOfJoining: this.form.dateOfJoining,
            dateOfConfirmation: this.form.dateOfConfirmation,
            isManager: this.form.isManager,
            permanentAddress1: this.form.permanentAddress1,
            permanentAddress2: this.form.permanentAddress2,
            permanentAddress3: this.form.permanentAddress3,
            permanentCountryId: this.form.permanentCountryId,
            permanentStateId: this.form.permanentStateId,
            permanentCityId: this.form.permanentCityId,
            permanentZipCode: this.form.permanentZipCode,
            isSameAsPermanentAdd: this.form.isSameAsPermanentAdd?1:0,
            communicationAddress1: this.form.communicationAddress1,
            communicationAddress2: this.form.communicationAddress2,
            communicationAddress3: this.form.communicationAddress3,
            communicationCountryId: this.form.communicationCountryId,
            communicationStateId: this.form.communicationStateId,
            communicationCityId: this.form.communicationCityId,
            communicationZipCode: this.form.communicationZipCode,
          };
          joineeInfo(data).then((res) => {
            if (res.info === 1) {
              this.infoMessage.isVisible = true;
              this.infoMessage.message = res.message;
            } else {
              this.showMessage.isVisible = true;
              this.showMessage.message = res.data[0].message;
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" module>
.formContent {
  h6 {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 20px;
  }
  p {
    text-align: justify;
    margin-bottom: 20px;
  }
  ol {
    padding-left: 40px;
    margin-bottom: 20px;
    li {
      margin-bottom: 10px;
    }
  }
}
</style>
