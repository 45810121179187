import Ajax from "Utils/ajax";
export const getAllShift = (data) => Ajax.post('hr/getAllShift',data); 
export const getAllCompanyList = (data) => Ajax.post('hr/getAllCompanyList',data);
export const documentCategory = (data) => Ajax.post('hr/documentCategory',data);
export const settingUserList = (data) => Ajax.post('hr/settingUserList',data); 
export const getDepartmentByCompanyId = (data) => Ajax.post('setting/getSuggestionDepartment',data);
export const getAllGrade = (data) => Ajax.post('setting/getAllGrade',data);
export const getCountry = (data) => Ajax.post('setting/getCountry',data);
export const getState = (data) => Ajax.post('setting/getState',data);
export const getCity = (data) => Ajax.post('setting/getCity',data);
export const getBloodGroup = (data) => Ajax.post('setting/getBloodGroup',data);
export const addUpdateStaffInfo = (data) => Ajax.post('setting/addUpdateStaffInfo',data);
export const updateUserDetails = (data) => Ajax.post('setting/updateUserDetails',data);
export const addUserRoleV1 = (data) => Ajax.post('setting/addUserRoleV1',data);
export const getRolesByUserID = (data) => Ajax.post('setting/getRolesByUserID',data);
export const getStaffInfoByUserID = (data) => Ajax.post('setting/getStaffInfoByUserID',data);
export const getStaffDetailsByUserID = (data) => Ajax.post('setting/getStaffDetailsByUserID',data);
export const getAllDesignation = (data) => Ajax.post('setting/getAllDesignation',data);
export const getAllDivision = (data) => Ajax.post('setting/getAllDivision',data);
export const getAllHRGroup = (data) => Ajax.post('setting/getAllHRGroup',data);
export const getReportingLocation = (data) => Ajax.post('setting/getReportingLocation',data);
export const getManagerList = (data) => Ajax.post('setting/getManagerList',data);
export const getStaffAwardList = (data) => Ajax.post('setting/getStaffAwardList',data);
export const getStaffDocumentList = (data) => Ajax.post('setting/getStaffDocumentList',data);

// new Joinee Api's created by sagar 21-09-2023
export const joineeInfo = (data) => Ajax.post('setting/joineeInfo',data);
// sagar add apis for salary fixations
export const getGrades = (data) => Ajax.post('setting/getAllGrade',data);
export const getDesignation = (data) => Ajax.post('setting/getAllDesignation',data);
export const getAllShiftType = (data) => Ajax.post('setting/getAllShiftType',data);
export const getSalaryFixation = (data) => Ajax.post('account/getSalaryFixation',data);
export const generateSalaryFixation = (data) => Ajax.post('account/generateSalaryFixation',data);
export const staffApprasial = (data) => Ajax.post('account/staffApprasial',data);